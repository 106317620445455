//libs
import React from 'react';
//components

const Home = () => {
  return (
    <div className='home-page'>
      <div className='page-header-container'>
        <header className='page-header'>
          <h1>
            for the th<span>iii</span>nkers by the th<span>iii</span>nkers
          </h1>
        </header>
      </div>
      <div className='article'>
        <div className='article-content-container'>
          LiiiGHTWAVE is a research & development company focused on developing
          open-source software and sharing open data derived from the research
          that we conduct, with everyone.
        </div>
      </div>
    </div>
  );
};

export default Home;
